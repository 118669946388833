<template>
    <label :for="inputId"
           class="switch">
        <input :id="inputId"
               :checked="value"
               name="checkbox-switch"
               type="checkbox"
               @input="updateValue">
        <span class="slider round"></span>
    </label>
</template>

<script setup lang="ts">

defineProps<{
    value: boolean,
}>();

const emit = defineEmits(['input']);
const inputId = `checkbox-switch-${Math.random().toString(36).substr(2)}`;

function updateValue(event) {
    emit('input', event.target.checked);
}
</script>

<style scoped lang="less">
.switch {
    position: relative;
    display: inline-block;
    width: 4.4rem;
    min-width: 4.4rem;
    height: 2.4rem;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: .4s;

    @apply bg-brown-50;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 1.8rem;
    width: 1.8rem;
    left: .3rem;
    bottom: .3rem;
    transition: .4s;

    @apply bg-sand-10;
  }

  input:checked + .slider {
    @apply bg-brown-80;
  }

  input:checked + .slider:before {
    transform: translateX(2rem);
  }

  /* Rounded sliders */
  .slider {
    &.round {
        border-radius: 34px;
      }
  }

  .slider {
      &.round:before {
        border-radius: 50%;
      }

  }

</style>
