<template>
    <section>
        <breadcrumb v-if="!content.hideBreadcrumbPath"/>
        <BlocksContainer :blocks="content && content.blocks"/>
        <div class="o-container">
            <SearchProductControl #default="{facets, activeSortByKey, products, totalCount}"
                                  :term="searchTerm"
                                  :category-id="categoryId"
                                  :brand-id="brandId"
                                  :page-size="pageSize"
                                  :cross-category="content.crossCategory"
                                  :tracking-page-type="trackingPageType"
                                  @searching="searching">
                <div v-if="shouldShowProductList(products)">
                    <article v-if="hasContent"
                             class="flex flex-col items-center pt-18"
                             :class="{'pb-25 md:pt-40 md:pb-40': isTopCategory, 'pb-32 md:pt-40 md:pb-40': !isTopCategory}">
                        <div v-if="content.image && !isTopCategory"
                             class="relative z-0 -mt-6 md:mt-8 px-20 ls:px-40 md:px-60 c-header__icon text-center mb-4 md:mb-10">
                            <ResponsiveIconWrap
                                :image="content.image"
                                :alt-text="content.title"
                                class="max-w-120 lg:max-w-100"/>
                        </div>
                        <h1 v-if="content.title"
                            class="mt-5 md:mt-4 mb-0 uppercase font-brandon text-center">
                            <UmbracoText class="block font-normal leading-title text-30"
                                         :text="content.title"/>
                        </h1>
                    </article>
                    <ShowSubCategories :categories="subCategories"
                                       :show-images="isTopCategory"/>
                    <div ref="filtersScrollPos"
                         :class="{ 'mt-60 md:mt-73': content.image, 'mt-55 md:mt-65': !content.image }"/>
                    <SplitTests feature-key="Fsst-available_online_toggle"
                                fallback-variation="available_online_toggle_OFF"
                                is-fallback>
                        <template #available_online_toggle_ON>
                            <div class="category-page-filters sticky bg-sand-10 z-filters top-0 pb-20 pt-24 -mt-32 -mx-1 md:pb-0">
                                <div class="h-full flex flex-col-reverse md:grid md:gap-x-20 md:gap-y-20 md:grid-cols-4 md:grid-rows-[1fr_auto] md:items-end">
                                    <div class="o-grid-item grid-w-2__only-desktop md:mb-0 md:mt-auto md:text-right text-left uppercase text-12 font-brandon font-normal">
                                        {{ $translate('search.Overlay.TotalProductResultsHeading', totalCount) }}
                                    </div>
                                    <div class="o-grid-item grid-w-2__only-desktop">
                                        <div class="flex flex-row-reverse items-center md:flex-row">
                                            <button class="flex items-center ml-auto mb-8 md:mb-0 md:mt-auto md:ml-0 md:mr-auto"
                                                    :aria-label="$translate('search.Filters.ShowMoreFilters')"
                                                    @click="facetOverlayActive = FacetOverlayModes.otherFacets">
                                                <c-icon name="filter"
                                                        class="w-16"/>
                                                <span class="pl-5 uppercase text-12 font-brandon font-normal">
                                                    <span class="md:hidden">
                                                        {{ $translate('search.Filters.ShowMoreFilters') }}

                                                    </span>
                                                    <span class="hidden md:inline">
                                                        {{ $translate('search.Filters.ShowMoreFiltersMinor') }}
                                                    </span>
                                                </span>
                                            </button>
                                            <SearchBooleanFacet :facet-group="getMatchingFacetGroup(facets, 'inStock')"
                                                                class="mb-8 md:flex md:-mb-5"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template #available_online_toggle_OFF>
                            <div class="category-page-filters sticky z-filters top-0 h-52 xl:h-60 -mt-20">
                                <div class="bg-white-100 h-full o-grid items-end">
                                    <div class="o-grid-item grid-w-2__only-desktop md:text-right text-left uppercase text-12 font-brandon font-normal">
                                        {{ $translate('search.Overlay.TotalProductResultsHeading', totalCount) }}
                                    </div>
                                    <div class="o-grid-item grid-w-2__only-desktop">
                                        <button class="flex items-center ml-auto md:ml-0 md:mr-auto"
                                                :aria-label="$translate('search.Filters.ShowMoreFilters')"
                                                @click="facetOverlayActive = FacetOverlayModes.otherFacets">
                                            <c-icon name="filter"
                                                    class="w-16"/>
                                            <span class="pl-5 uppercase text-12 font-brandon font-normal">
                                                <span class="md:hidden">
                                                    {{ $translate('search.Filters.ShowMoreFilters') }}
         
                                                </span>
                                                <span class="hidden md:inline">
                                                    {{ $translate('search.Filters.ShowMoreFiltersMinor') }}
                                                </span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </SplitTests>

                    <ShowChosenFacets v-if="facets.length"
                                      :facet-groups="facets"/>

                    <div class="o-grid c-product-list">
                        <template v-if="products.length">
                            <ProductTile v-for="(product, productIndex) in products"
                                         :key="product.id"
                                         :product="product"
                                         :ignore-inventory="false"
                                         :image-alt="[getProductTilePostfix, product.name, product.subtitle].join(' - ')"
                                         class="o-grid-item"
                                         :style="{order:(productIndex + 1)}"
                                         :data-legacy-sort="(productIndex + 1)"
                                         :tracking-index="productIndex"
                                         :tracking-list-provider="'Catalog'"/>
                        </template>

                        <template v-if="shouldShowSkeleton">
                            <ProductSkeleton v-for="(skeleton, skeletonIx) in skeletonArray"
                                             :key="'skeleton_' + skeletonIx"
                                             class="o-grid-item"/>
                        </template>

                        <template v-if="isNotFiltered(facets) && products.length">
                            <BlockRender v-for="(enrichment, enrichmentIndex) in enrichmentsList(products)"
                                         :key="`${enrichmentIndex}-${enrichment.content.position || 'x'}`"
                                         :class="getClass(enrichment.content)"
                                         class="o-grid-item h-full"
                                         :style="{order:getStyle(enrichment.content.position)}"
                                         :data-legacy-sort="getStyle(enrichment.content.position)"
                                         :block="enrichment"
                                         :tracking-position="enrichmentIndex"/>
                        </template>
                    </div>
                    <div v-if="!isSearching && !products.length"
                         class="text-center bg-white-300 p-20 md:p-40"
                         :class="{'mb-40': isMultiCategory}">
                        <div>
                            <ResponsiveIcon class="mb-20 md:mb-40"
                                            bg-color="f9f7f0"
                                            image-url="/images/empty-search.png"
                                            :width="160"
                                            :height="160"
                                            :alt-text="$translate('category.NoProducts')"/>
                        </div>
                        <span class="font-brandon uppercase">{{ $translate('category.NoProducts') }}</span>
                    </div>

                    <div v-if="products && totalCount > products.length"
                         class="flex"
                         :class="{'mb-40': isMultiCategory}">
                        <NextPageLink class="mt-40 inline-flex mx-auto"
                                      item-class="c-btn c-btn--primary"
                                      :is-searching="isSearching"
                                      :aria-label="$translate('search.Overlay.Products.ViewMoreProductResults')">
                            <span class="c-btn__text">{{ $translate('search.Overlay.Products.ViewMoreProductResults') }}</span>
                        </NextPageLink>
                    </div>

                    <PortalOverlay :show.sync="facetOverlayActiveInternally"
                                   wrapper-class="ls:max-w-400 ml-auto"
                                   :disable-body-scroll="false"
                                   side="right">
                        <div>
                            <div class="bg-white-100 min-h-screen px-20 pb-20">
                                <FacetControls :facets="facets"
                                               :active-sort-by-key="activeSortByKey"/>
                            </div>
                            <footer class="sticky bottom-0 left-0 right-0 z-5 bg-white-100 p-20 flex shadow-modal-footer">
                                <button class="c-btn c-btn--primary-outlined w-1/2 mr-5"
                                        @click="clearAllFacets">
                                    <span class="c-btn__text c-btn__text--font-light w-full">
                                        {{ $translate('search.Filters.ClearAll') }}
                                    </span>
                                </button>
                                <button class="c-btn c-btn--primary w-1/2 ml-5"
                                        @click="facetOverlayActive = FacetOverlayModes.none">
                                    <span class="c-btn__text c-btn__text--font-light w-full">{{ $translate('search.Filters.MobileCloseFilters', totalCount) }}</span>
                                </button>
                            </footer>
                        </div>
                    </PortalOverlay>
                </div>
            </SearchProductControl>

            <footer v-if="!brandId && !isMultiCategory"
                    class="category-page-next-category-trigger relative py-30 md:py-60">
                <div class="mb-30 text-center">
                    <span class="inline-block w-1 mx-auto bg-brown-80 h-100 opacity-25"/>
                </div>
                <header class="text-center uppercase font-brandon font-normal mb-60">
                    <span class="block text-10 leading-loose mb-5">{{ $translate('category.NextCategory.Supplementary') }}</span>
                    <UmbracoText class="block text-30 leading-sm"
                                 :text="content?.nextCategoryNavigationTitle"/>
                </header>
                <div class="c-next-category-wrapper relative flex flex-wrap -mx-10 md:-mx-15">
                    <article v-for="(nextProduct, nextProductIndex) in nextCategoryProducts"
                             :key="nextProduct.id"
                             class="w-1/2 px-10 mb-20 md:mb-60 md:w-1/4 md:px-15">
                        <ProductTile :product="nextProduct"
                                     :enable-tracking="false"
                                     :ignore-inventory="true"
                                     :tracking-index="nextProductIndex"
                                     :tracking-list-provider="'Catalog'"
                                     tracking-list-type="NextCategory"/>
                    </article>
                    <template v-if="!nextCategoryProducts?.length">
                        <ProductSkeleton v-for="(skeleton, skeletonIx) in 4"
                                         :key="'skeleton_next_' + skeletonIx"
                                         class="w-1/2 px-10 mb-20 md:mb-60 md:w-1/4 md:px-15"/>
                    </template>
                    <RouterLink v-if="content.nextCategoryUrl"
                                :to="content.nextCategoryUrl"
                                :title="$translate('category.NextCategory.LinkTitle')"
                                class="absolute inset-0 z-3"/>
                </div>
                <div v-if="content?.nextCategoryUrl"
                     class="absolute bottom-0 w-full pb-40 text-center z-3 md:pb-80">
                    <RouterLink :to="content?.nextCategoryUrl"
                                :title="$translate('category.NextCategory.LinkTitle')"
                                class="c-btn c-btn--primary c-btn--sm inline-flex">
                        <span class="c-btn__text text-10 md:text-14">
                            {{ $translate('category.NextCategory.LinkTitle') }}
                        </span>
                    </RouterLink>
                </div>
            </footer>
        </div>
        <BlocksContainer :blocks="content && content.blocksBelow"
                         :class="{'category-page-next-category-trigger': isMultiCategory}"/>
    </section>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { defineProps } from 'vue';
import { 
    CategoryDetailsContentModel, 
    FacetGroup, 
    FeaturedProductBlockModel, 
    ContentHubRecommendedProductEnrichmentBlock, 
    JsonContent, 
    NavigationLinkItem, 
    ProductSearchResultNew, 
    v4, 
    BrandPageContentModel 
} from '@/types/serverContract';
import Api from '@/project/http/api';
import range from 'lodash-es/range';
import { styleFunctionsForThemedContent } from '@/project/shared/styleFunctionsForThemedContent';
import urlHelper from '@/project/facets/urlHelper.service';
import scrollService from '@/core/scroll/scroll.service';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import bus from '@/core/bus';
import { 
    PageHeaderResetScrollBehaviour, 
    PageHeaderStopScrollBehaviour, 
    PageHeaderToggleHidden, 
    PageHeaderToggleShowAtNextCategory 
} from '@/project/config/constants';
import { doDataLayerPush, getExistingFacetsForTracking } from '@/project/tracking/googleTagManager.utils';
import spaStore from '@/core/spa/store/spa.store';
import SplitTests from '@/project/growthbook/SplitTests.vue';
import UmbracoText from '@/project/shared/UmbracoText.vue';
import SearchProductControl from '@/project/search/SearchProductControl.vue';
import FacetControls from '@/project/search/FacetControls.vue';
import ShowChosenFacets from '@/project/facets/ShowChosenFacets.vue';
import Breadcrumb from '@/project/shared/navigation/Breadcrumb.vue';
import ProductSkeleton from '@/project/product/ProductSkeleton.vue';
import ResponsiveIcon from '@/core/responsive/image/ResponsiveIcon.vue';
import NextPageLink from '@/project/search/NextPageLink.vue';
import ShowSubCategories from './categoryPage/ShowSubCategories.vue';
import ResponsiveIconWrap from '@/project/shared/ResponsiveIconWrap.vue';
import SearchBooleanFacet from '@/project/search/SearchBooleanFacet.vue';

enum FacetOverlayModes {
    'none' = 0,
    'otherFacets'
}

const props = defineProps<{
    content: CategoryDetailsContentModel | BrandPageContentModel;
}>();

const searchTerm = ref('');
const filtersScrollPos = ref<null | HTMLElement>(null);
const nextCategorySearchResult = ref<ProductSearchResultNew | null>(null);
const nextCategoryLookupTimeout = ref<any>(null);
const isSearching = ref(true);
const skeletonArray = range(8);
const facetOverlayActiveInternally = ref(false);
const facetOverlayActive = ref<FacetOverlayModes>(FacetOverlayModes.none);
const pageSize = ref(48);
let _scrollTrigger: ScrollTrigger | undefined;

onMounted(() => {
    bus.emit(PageHeaderStopScrollBehaviour);
    const filters = document.querySelector('.category-page-filters');

    if (!filters) return;

    _scrollTrigger = ScrollTrigger.create({
        trigger: '.category-page-filters',
        endTrigger: '.category-page-next-category-trigger',
        start: 'top-=50px top',
        end: 'top top',
        invalidateOnRefresh: true,
        onEnter: () => {
            bus.emit(PageHeaderToggleHidden, true);
            filters.classList.add('border-b', 'border-white-200');
        },
        onEnterBack: () => bus.emit(PageHeaderToggleShowAtNextCategory, false),
        onLeave: () => bus.emit(PageHeaderToggleShowAtNextCategory, true),
        onLeaveBack: () => {
            bus.emit(PageHeaderToggleHidden, false);
            filters.classList.remove('border-b', 'border-white-200');
        }
    });
});

onBeforeUnmount(() => {
    if (nextCategoryLookupTimeout.value) {
        clearTimeout(nextCategoryLookupTimeout.value);
    }

    if (_scrollTrigger) {
        _scrollTrigger.kill();
    }

    bus.emit(PageHeaderResetScrollBehaviour);
});

const shouldShowSkeleton = computed(() => {
    const hasPaging = urlHelper.getQueryParam('page');
    return isSearching.value && !hasPaging;
});

const categoryId = computed(() => props.content?.categoryId || '');
const brandId = computed(() => props.content?.brandId || '');
const isMultiCategory = computed(() => !!props.content?.crossCategory);
const hasContent = computed(() => !!props.content?.image || !!props.content?.title);
const isTopCategory = computed(() => !!props.content?.isTopCategory);

function getMatchingFacetGroup(facetGroups: FacetGroup[], key: string): FacetGroup | undefined {
    return facetGroups.find(f => f.key === key);
}

const trackingPageType = computed(() => {
    if (!props.content) return '';
    if (props.content.crossCategory === 'news') return 'newsListPage';
    if (['campaign', 'brand'].includes(props.content.crossCategory)) return 'campaignListPage';
    return 'categoryListPage';
});

const subCategories = computed<NavigationLinkItem[]>(() =>
    props.content.subCategories?.length
        ? props.content.subCategories
        : props.content.siblingsAndSelfCategories ?? []
);

const nextCategoryProducts = computed(() =>
    nextCategorySearchResult.value?.searchResultItems || []
);

const searching = (searching: boolean)=> {
    isSearching.value = searching;
    if (!searching) {
        nextTick(() => {
            const hasScrolled = document.documentElement.scrollTop || window.scrollY;
            if (facetOverlayActive.value === FacetOverlayModes.otherFacets && hasScrolled && filtersScrollPos.value) {
                scrollService.scrollToElement(filtersScrollPos.value);
            }
        });
    }
};

watch(facetOverlayActive, (newState: FacetOverlayModes) => {
    facetOverlayActiveInternally.value = newState !== FacetOverlayModes.none;
});

watch(facetOverlayActiveInternally, (newState: boolean) => {
    if (!newState) facetOverlayActive.value = FacetOverlayModes.none;
});

watch(categoryId, async() => {
    if (isMultiCategory.value) return;

    if (nextCategoryLookupTimeout.value) {
        clearTimeout(nextCategoryLookupTimeout.value);
    }
    nextCategorySearchResult.value = null;

    nextCategoryLookupTimeout.value = setTimeout(() => {
        loadNextCategory();
    }, 2000);
}, { immediate: true });

const loadNextCategory = async() => {
    if (props.content.nextCategoryId) {
        nextCategorySearchResult.value = await Api.catalog.simpleCategorySearch(
            props.content.nextCategoryId,
            4
        );
    }
};

const isNotFiltered = (facets: FacetGroup[]): boolean => {
    return !(
        facets &&
        (facets.find(f => f?.values?.find(v => v.selected)) ||
            urlHelper.getQueryParam('unitPriceInclVat'))
    );
};

const getStyle = (position: number) => position || 0;

const enrichmentsList = (products: v4.Products.ProductSimple[]): JsonContent[] => {
    if (products.length === 0) return [];
    return props.content.enrichments.filter(
        enrichment => enrichment.content.position <= products.length
    ) as JsonContent[];
};

const shouldShowProductList = (products: v4.Products.ProductSimple[]): boolean => {
    return !brandId.value || products.length > 0;
};

const getClass = (
    enrichment: FeaturedProductBlockModel | ContentHubRecommendedProductEnrichmentBlock
): string[] => {
    if (!enrichment) return [];
    const textColor = 'textColor' in enrichment ? enrichment.textColor : 'text-theme-2';
    const classes = [styleFunctionsForThemedContent(textColor)];

    if (enrichment.size) {
        classes.push(`grid-w-${enrichment.size.cols}`);
        classes.push(`grid-h-${enrichment.size.rows}`);
    }

    return classes;
};

const getProductTilePostfix = computed(() => {
    return (spaStore.metadata && spaStore.metadata.navigationTitle) || '';
});

const clearAllFacets = () => {
    const eventLabel = getExistingFacetsForTracking();
    doDataLayerPush({
        event: 'GAEvent',
        eventCategory: 'Facet',
        eventAction: 'Remove all facets',
        eventLabel,
        eventValue: undefined
    });
    urlHelper.clearFacets();
};

</script>


<style lang="less" scoped>
.c-next-category-wrapper {
    &::after {
        @apply absolute inset-0 z-2;
        content: '';
        background-image: linear-gradient(0deg, rgba(255,253,245,1) 20%, rgba(229,237,231,0) 100%);
    }
}
</style>
