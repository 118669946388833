<template>
    <div class="c-checkbox"
         :class="error ? 'has-validation-error' : ''">
        <label v-if="error && errorPlacement === 'above'"
               :for="id"
               class="mb-2 block text-red-500 text-11 md:text-12">
            {{ error }}
        </label>
        <div :class="{ 'c-checkbox--error-below': error && errorPlacement === 'below' }">
            <input
                :id="id"
                ref="input"
                v-prohibit-zoom
                class="c-checkbox__input"
                type="checkbox"
                :name="name"
                :value="value"
                :checked="shouldBeChecked"
                v-bind="$attrs"
                @change="updateValue"
                v-on="listeners">
            <label class="c-checkbox__label"
                   :class="{ 'c-checkbox__label--error': error }"
                   :for="id">
                <span
                    class="c-checkbox__pseudo-input u-trans u-trans-bg"
                    :class="[
                        {
                            'c-checkbox__pseudo-input--checked': shouldBeChecked,
                            'c-checkbox__pseudo-input--disabled': showDisabled,
                            'border-red-500': error && (errorPlacement === 'default' || errorPlacement === 'above')
                        },
                        pseudoClass
                    ]">
                    <span
                        class="c-checkbox__checkmark u-trans u-trans-opacity flex items-center xy-center"
                        :class="{ 'text-sand-10': !showDisabled, 'text-white-180': showDisabled }">
                        <c-icon name="checkmark"
                                width="10"
                                height="10"/>
                    </span>
                </span>
                <span :class="labelWrapClass">
                    <span v-if="label"
                          :class="labelClass"
                          class="c-checkbox__label-normal block">
                        {{ label }}
                    </span>
                    <span
                        v-else-if="htmlLabel"
                        v-delegate-html-links
                        class="block"
                        :class="labelClass"
                        v-html="htmlLabel"/>
                    <span
                        v-if="htmlDescription"
                        v-delegate-html-links
                        class="block text-12 font-gibson-light"
                        :class="{ 'mt-4': label || htmlLabel }"
                        v-html="htmlDescription"/>
                    <slot name="htmlDescription"/>
                    <span v-if="error && errorPlacement === 'default'"
                          class="my-5 block text-red-600">
                        {{ error }}
                    </span>
                </span>
            </label>
        </div>
        <label v-if="error && errorPlacement === 'below'"
               :for="id"
               class="mt-16 block text-red-600">
            {{ error }}
        </label>
    </div>
</template>

<script setup lang="ts">
import useFieldValidation from '@/core/form/useFieldValidation';
import translateFilter from '@/core/translation/translate.filter';
import { computed, withDefaults, ref, useAttrs, useListeners } from 'vue';
import { z } from 'zod';

const props = withDefaults(defineProps<{
    label?: string,
    labelClass?: string,
    pseudoClass?: string,
    htmlLabel?: string,
    htmlDescription?: string,
    name: string,
    value?: string,
    trueValue?: boolean,
    falseValue?: boolean,
    modelValue: boolean,
    labelWrapClass?: string,
    errorPlacement?: string,
    required?: boolean,
    errorLabelKey?: string,
}>(), {
    label: '',
    value: '',
    labelClass: '',
    pseudoClass: '',
    trueValue: true,
    falseValue: false,
    htmlLabel: '',
    htmlDescription: '',
    errorPlacement: 'default',
    required: false,
    labelWrapClass: '',
    errorLabelKey: '',
});

if (props.required && !props.errorLabelKey) {
    throw new Error('You must supply an errorLabelKey when mustBeChecked is true');
}

const emit = defineEmits(['change']);
const $attrs = useAttrs();
const $listeners = useListeners();
const input = ref<HTMLInputElement>();
const id = ref(props.name + Math.round(Math.random() * 100000));
const constraints = props.required 
    ? z.boolean().refine(value => value, translateFilter(props.errorLabelKey)) 
    : z.boolean().optional();

const shouldBeChecked = computed(() => {
    return props.modelValue === props.trueValue;
});

const { field } = useFieldValidation(props.name, props.label, constraints, shouldBeChecked);

const updateValue = (event: Event) => {
    const isChecked = (event.target as HTMLInputElement).checked;
    emit('change', isChecked ? props.trueValue : props.falseValue);
};

const showDisabled = computed(() => !!$attrs.disabled);

const error = computed(() => field.error);

const listeners = computed(() => {
    // eslint-disable-next-line
    const { change, ...rest } = $listeners;
    return rest;
});
</script>
