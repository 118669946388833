<template>
    <div v-if="isVideo"
         class="aspect-ratio-3-4 relative overflow-hidden">
        <Video
            :key="media.url"
            :vimeo-video-url="`${media.url}#t=0.001`"
            :vimeo-video-id="vimeoUrlToId(media.url)"
            vimeo-muted
            :vimeo-loop="false"
            :play="shouldPlay"
            :show-player-controls="false"
            class="relative h-full w-full object-cover"
            @ended="handleVideoStreamEnded"
            @paused="handleVideoStreamEnded"/>
        <transition
            mode="out-in"
            leave-active-class="animated fadeOut u-anim-dur-200"
            enter-active-class="animated fadeIn u-anim-dur-200">
            <div v-if="!shouldPlay"
                 class="absolute inset-0 z-1 flex cursor-pointer"
                 @click="handleImageClick()">
                <button aria-label="generic.Open"
                        class="m-auto"
                        @click="handlePlayVideoClick()">
                    <cIcon name="play-video"
                           original
                           class="relative z-0 h-30 w-30 text-brown-80 md:h-40 md:w-40"/>
                </button>
            </div>
        </transition>
    </div>
    <div v-else
         class="relative">
        <div class="relative flex w-full flex-col">
            <ResponsiveImage
                class="u-image-zoom h-auto"
                :width-on-screen="widthOnScreen"
                :aspect-ratio="3 / 4"
                :image-url="media.url"
                :media-url-config="media.mediaUrlConfig"
                format="webp"
                :quality="80"
                bg-color="#faf7f0"
                load-type="eager"
                :width="width"
                :alt="selectedVariant.seoTitle"
                :height="height"/>
            <a class="absolute inset-0 cursor-pointer"
               @click="handleImageClick()"></a>
        </div>
    </div>
</template>

<script setup lang="ts">
import bus from '@/core/bus';
import { Media, v4 } from '@/types/serverContract';
import { computed, defineAsyncComponent } from 'vue';
import { PDP_IMAGE_FULLSCREEN } from '../productHelper.utils';
import { vimeoUrlToId } from '@/project/shared/string.util';
import useVideo from '@/project/product/details/useVideo';
const Video = defineAsyncComponent(() => import('@/core/Video.vue'));

const props = defineProps<{
    enableFavoritedByBadge: boolean;
    details: v4.Products.ProductDetails;
    selectedVariant: v4.Products.ProductDetailsVariant;
    media: Media;
    isVideo: boolean;
    width: number;
    height: number;
    widthOnScreen: any;
    index: number;
}>();

const { shouldPlayVideo, togglePlayVideo, activeIndex } = useVideo(props.selectedVariant.sortedMedia);

const handleImageClick = () => {
    if (shouldPlay.value) return;
    activeIndex.value = props.index;
    togglePlayVideo(false);
    bus.emit(PDP_IMAGE_FULLSCREEN, props.index);
};

const shouldPlay = computed(() => {
    return props.index === activeIndex.value && shouldPlayVideo.value;
});

const handlePlayVideoClick = () => {
    activeIndex.value = props.index;
    togglePlayVideo(true);
};

const handleVideoStreamEnded = () => {
    togglePlayVideo(false);
};
</script>

<style scoped>
.aspect-ratio-3-4 {
    aspect-ratio: 3 / 4;
}
</style>
