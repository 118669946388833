
import translateFilter from '@/core/translation/translate.filter';
import { z } from 'zod';

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
    if (issue.code === z.ZodIssueCode.too_small && issue.type === 'string' && issue.minimum === 1) {
        return { message: translateFilter('validation.Required', '__field__') };
    }
    if (issue.code === z.ZodIssueCode.too_small && issue.type === 'string') {
        return { message: translateFilter('validation.Min', '__field__', issue.minimum) };
    }
    if (issue.code === z.ZodIssueCode.too_big && issue.type === 'string') {
        return { message: translateFilter('validation.Max', '__field__', issue.maximum) };
    }
    if (issue.code === z.ZodIssueCode.invalid_string && issue.validation === 'email') {
        return { message: translateFilter('validation.Email', '__field__') };
    }
    if (issue.code === z.ZodIssueCode.invalid_string && issue.validation === 'regex') {
        return { message: translateFilter('validation.Regex', '__field__') };
    }

    return { message: ctx.defaultError };
};

z.setErrorMap(customErrorMap);
