import Vue from 'vue';
import ResponsiveImage from '@/core/responsive/image/ResponsiveImage.vue';
import ResponsiveIconWrap from '@/project/shared/ResponsiveIconWrap.vue';
import BlockRender from '@/project/shared/BlockRender.vue';
import UmbracoText from '@/project/shared/UmbracoText.vue';
import CulturePicker from '@/project/layout/culture/CulturePicker.vue';
import '../spa/pages';
import '../spa/blocks';
import '../product';
import HorizontalLine from '@/project/shared/horizontalLine.vue';

import TextInputNoValidation from '@/project/shared/form/TextInputNoValidation.vue';
import CheckboxCtrl from '@/project/shared/form/CheckboxCtrl.vue';
import TextInput from '@/project/shared/form/TextInput.vue';
import RadioButtonCtrl from '@/project/shared/form/RadioButtonCtrl.vue';
import PhonePrefixSelect from '@/project/shared/form/PhonePrefixSelect.vue';
import CountrySelect from '@/project/shared/form/CountrySelect.vue';
import BirthDayInput from '@/project/shared/form/BirthDayInput.vue';
import CheckboxSwitch from '@/project/shared/form/CheckboxSwitch.vue';
import Form from '@/core/form/Form.vue';

const EmailSuggest = () => import('@/project/login/EmailSuggest.vue');
const LoginOverlay = () => import('@/project/login/LoginOverlay.vue');


// Global components that we want to use on multiple pages
Vue.component('ResponsiveImage', ResponsiveImage);
Vue.component('ResponsiveIconWrap', ResponsiveIconWrap);
Vue.component('BlockRender', BlockRender);
Vue.component('CheckboxCtrl', CheckboxCtrl);
Vue.component('TextInput', TextInput);
Vue.component('TextInputNoValidation', TextInputNoValidation);
Vue.component('RadioButtonCtrl', RadioButtonCtrl);
Vue.component('PhonePrefixSelect', PhonePrefixSelect);
Vue.component('CountrySelect', CountrySelect);
Vue.component('BirthDayInput', BirthDayInput);
Vue.component('EmailSuggest', EmailSuggest);
Vue.component('LoginOverlay', LoginOverlay);
Vue.component('CulturePicker', CulturePicker);
Vue.component('HorizontalLine', HorizontalLine);
Vue.component('CheckboxSwitch', CheckboxSwitch);
Vue.component('UmbracoText', UmbracoText);
// eslint-disable-next-line vue/no-reserved-component-names
Vue.component('Form', Form);
