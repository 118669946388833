export type FormField = {
    name: string,
    dirty: boolean,
    validated: boolean,
    required: boolean,
    valid: boolean,
    invalid: boolean,
    error: string | null,
    validate: () => Promise<string | null>
}

export const formInjectionKey = Symbol('formInjection');

export type FormInjectionContext = {
    registerOrUpdateField: (field: FormField) => void;
    unregisterField: (field: FormField) => void;
};
