<template>
    <header
        class="o-page-header" 
        :class="getPageClasses" 
        :aria-label="$translate('generic.AriaLabels.PageHeader')"
        @keyup.esc="closeSearchOverlay">
        <transition mode="out-in"
                    leave-active-class="max-h-0"
                    enter-active-class="max-h-0">
            <NotificationBar v-if="getNotificationBar"/>
        </transition>
        <div class="navigation-bar-wrapper h-52 w-full flex items-center xl:h-60">
            <div
                ref="navigationBar"
                class="transition w-full z-header bg-sand-10 duration-500"
                :class="{
                    'navigation-bar--hidden fixed left-0 right-0': navigationBarHidden,
                    'navigation-bar--animate-in': navigationBarAnimateIn
                }">
                <div class="o-container relative h-52 z-2 w-full xl:h-60 lg:max-w-1440">
                    <div class="flex items-center w-full h-full">
                        <MobileMenuButton class="xl:hidden"
                                          @on-open-navigation="openNavigation"/>
                        <div class="relative">
                            <router-link v-if="headerNavigation?.frontPage?.url"
                                         :to="headerNavigation.frontPage.url"
                                         :title="headerNavigation.frontPage.name"
                                         class="absolute inset-0"/>
                            <cIcon name="logo-collapsed"
                                   class="text-brown-80 w-156"/>
                        </div>
                        <PageHeaderMenuItems
                            class="hidden xl:block" 
                            @products-selected="productsSelected"
                            @rooms-selected="roomsSelected"/>
                        <div class="hidden flex-shrink flex-1 justify-center md:flex">
                            <div class="bg-sand-20 w-full hidden md:block"
                                 :class="{ 'max-w-390 ml-auto mr-20 lg:mr-48': !headerNavigation, 'mx-20 lg:mx-48': headerNavigation }">
                                <Breakpoints>
                                    <template #min-md>
                                        <SearchInputWrap/>
                                    </template>
                                </Breakpoints>
                            </div>
                        </div>
                        <PageHeaderNavigationIcons :is-search-active-internally="isSearchActiveInternally"/>
                    </div>
                </div>
            </div>
        </div>
        <MobileMenuNavigation
            :navigation-active.sync="navigationActive"
            :initial-selection="initialNavigationSelection"/>
        <template v-if="!isSearchPage">
            <Breakpoints>
                <template #max-ls>
                    <PortalOverlay
                        portal="overlay"
                        outer-class="c-search__overlay"
                        :show.sync="searchActive"
                        :disable-body-scroll="true"
                        :show-close-button="false"
                        :show-blind="false"
                        side="right"
                        :focus-overlay="false">
                        <MobileSearchSuggestionsOverlay class="z-overlay fixed h-full bg-sand-10 overflow-y-auto inset-0 md:hidden"/>
                    </PortalOverlay>
                </template>
            </Breakpoints>
        </template>
    </header>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import navStore from '@/store/nav.store';
import MobileMenuNavigation from '@/project/shared/navigation/MobileMenuNavigation.vue';
import spaStore from '@/core/spa/store/spa.store';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import viewportEvents from '@/core/responsive/viewport/viewportEvents.service';
import bus from '@/core/bus';
import { navigationbarStore } from '@/store/navigationbar.store';
import {
    PageHeaderResetScrollBehaviour,
    PageHeaderStopScrollBehaviour,
    PageHeaderToggleHidden,
    PageHeaderToggleShowAtNextCategory
} from '@/project/config/constants';
import useHeaderNavigation from './useHeaderNavigation';
import PageHeaderNavigationIcons from './PageHeaderNavigationIcons.vue';
import useSearch from '@/project/search/useSearch';
import MobileMenuButton from '@/project/layout/page-header/MobileMenuButton.vue';
import PageHeaderMenuItems from '@/project/layout/page-header/PageHeaderMenuItems.vue';
import MobileSearchSuggestionsOverlay from '@/project/search/search-suggest/MobileSearchSuggestionsOverlay.vue';
import SearchInputWrap from '@/project/search/SearchInputWrap.vue';

const NotificationBar = () => import('@/project/shared/NotificationBar.vue');

const { headerNavigation } = useHeaderNavigation();
const { isSearchPage, searchActive, closeOverlay: closeSearchOverlay } = useSearch();

const navigationBar = ref<HTMLElement | null>(null);
const navigationBarHidden = computed(() => navigationbarStore.hidden);
const navigationActive = ref<boolean>(false);
const isSearchActiveInternally = ref<boolean>(false);
const initialNavigationSelection = ref<'Root' | 'Products' | 'Rooms'>('Root');
const _scrollTrigger = ref<ScrollTrigger | null>(null);

// Preserve PageHeader on scroll when search suggestionsoverlay is present
const navigationBarAnimateIn = computed(() => !searchActive.value ? navigationbarStore.animateIn : true);

onMounted(() => {
    gsap.registerPlugin(ScrollTrigger);
    initScrollBehaviour();
    bus.on(PageHeaderResetScrollBehaviour, initScrollBehaviour);
    bus.on(PageHeaderStopScrollBehaviour, stopScrollBehaviour);
    bus.on(PageHeaderToggleHidden, toggleHidden);
    bus.on(PageHeaderToggleShowAtNextCategory, toggleShowAtNextCategory);
    viewportEvents.setCallback(navigationbarStore.handleScroll);
});

onUnmounted(() => {
    viewportEvents.removeCallback(navigationbarStore.handleScroll);
});

const toggleHidden = (newVal: boolean) => {
    navigationbarStore.hidden = newVal;
};

const toggleShowAtNextCategory = (newVal: boolean) => {
    navigationbarStore.showAtNextCategory = newVal;
};

const initScrollBehaviour = () => {
    _scrollTrigger.value = ScrollTrigger.create({
        trigger: '.navigation-bar-wrapper',
        start: 'bottom top',
        end: 'max',
        invalidateOnRefresh: true,
        onEnter: () => toggleHidden(true),
        onLeaveBack: () => toggleHidden(false)
    });
};

const stopScrollBehaviour = () => {
    if (_scrollTrigger.value) _scrollTrigger.value.kill();
};

const getPageClasses = computed(() => {
    return navigationActive.value ? 'off-canvas-active' : 'print:hidden';
});

const storeNavigationActive = computed(() => {
    return navStore.navigationActive;
});

watch(storeNavigationActive, (newNavigationActive) => {
    if (!newNavigationActive) navigationActive.value = false;
});

watch(navigationActive, (newNavigationActive) => {
    navStore.setNavigationStateActive(navigationActive.value);
    if (!newNavigationActive) {
        initialNavigationSelection.value = 'Root';
    }
});

const openNavigation = () => {
    initialNavigationSelection.value = 'Root';
    navigationActive.value = true;
};

const productsSelected = () => {
    initialNavigationSelection.value = 'Products';
    navigationActive.value = true;
};

const roomsSelected = () => {
    initialNavigationSelection.value = 'Rooms';
    navigationActive.value = true;
};

const getNotificationBar = computed(() => {
    return spaStore.notificationBar;
});
</script>

<style lang="less" scoped>
.navigation-bar--hidden {
    top: -6.2rem;

    &.navigation-bar--animate-in {
        transform: translate3d(0, 6.2rem, 0);
    }
}

@screen md {
    .navigation-bar--hidden::after {
        @apply absolute bottom-0 left-0 right-0 top-50 -z-1 h-10 opacity-50;
        content: '';
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0) 100%);
    }
}

@screen xl {
    .navigation-bar--hidden::after {
        @apply top-60;
    }

    .navigation-bar--hidden {
        top: -7rem;

        &.navigation-bar--animate-in {
            transform: translate3d(0, 7rem, 0);
        }
    }
}
</style>
