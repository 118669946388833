<template>
    <div>
        <OverlayWrapper :open.sync="modalOpen"
                        body-bg-class="flex flex-col flex-1 bg-sand-10"
                        :show-scroll-indicator="false"
                        :disable-body-scroll="false"
                        styling=""
                        portal="remind-me-overlay"
                        @cancelled="modalOpen = false">
            <template #header>
                <OverlayHeader 
                    @close="modalOpen = false">
                    {{ $translate('product.Variant.RemindMe.OverlayTitle') }}
                </OverlayHeader>
            </template>
            <template #body>
                <div class="flex-1 flex flex-col">
                    <transition 
                        mode="out-in"
                        enter-active-class="animated fadeIn u-anim-dur-200"
                        leave-active-class="animated fadeOut u-anim-dur-200">
                        <Form v-if="state !== 'saved'"
                              class="pt-40 flex-1 flex flex-col"
                              :submit="saveRemindMe">
                            <div class="flex flex-col flex-1 items-center space-y-16 ">
                                <dotlottie-player
                                    :src="bell"
                                    autoplay
                                    loop
                                    class="h-120 w-120"/> 
                                <p class="px-70 font-gibson-light text-center text-14 leading-18">
                                    <template v-if="useUsersEmail">
                                        {{ $translate('product.Variant.RemindMe.DescriptionKnownUser') }}
                                    </template>
                                    <template v-else>
                                        {{ $translate('product.Variant.RemindMe.DescriptionAnonymousUser') }}
                                    </template>
                                </p>
                                <p v-if="useUsersEmail"
                                   class="text-center font-14 font-gibson-regular text-brown-80 text-14 leading-18 py-24">
                                    {{ email }}
                                </p>
                                <div v-else
                                     class=" pt-16 w-full">
                                    <TextInput
                                        v-model.trim="email"
                                        class="w-full"
                                        type="email"
                                        name="email"
                                        autocomplete="email"
                                        :constraints="validationRuleEmail()"
                                        :placeholder="$translate('product.Variant.RemindMe.PlaceholderEmail')"
                                        show-error
                                        :label="$translate('product.Variant.RemindMe.PlaceholderEmail')"
                                        :maxlength="130"/>
                                </div>
                                <div class="pt-8 place-self-start">
                                    <Checkbox
                                        :model-value="remindMeEmailsAccept"
                                        class="c-checkbox--small pt-8"
                                        pseudo-class="bg-transparent"
                                        name="remindMeEmailsAccept"
                                        :label="$translate('product.Variant.RemindMe.DoYouWishToReceiveEmailsTitle')"
                                        required
                                        error-placement="above"
                                        :show-error="true"
                                        error-label-key="validation.Custom.RemindMeEmailsAccept"
                                        @change="remindMeEmailsAccept = $event">
                                        <template #htmlDescription>
                                            <CheckoutTerms 
                                                label-key="product.Variant.RemindMe.AcceptToReceiveMailsHtml"/>
                                        </template>
                                    </Checkbox>
                                </div>
                            </div>
                            <div class="sticky -mx-24 px-24 py-24 bottom-0 bg-sand-10 border-t border-0 border-sand-40">
                                <button class="c-btn c-btn--new-primary w-full justify-center"
                                        type="submit"
                                        :disabled="state !== 'entry'">
                                    <c-icon name="bell"
                                            class="w-16 h-16"/>
                                    <span class="ml-8">{{ $translate('product.Variant.RemindMe.ButtonSave') }}</span>
                                </button>
                            </div>
                        </Form>
                        <div v-else
                             class="pt-40 flex flex-col flex-1">
                            <div class="flex flex-col items-center flex-1 space-y-16">
                                <dotlottie-player
                                    :src="checkmark"
                                    autoplay
                                    loop
                                    class="h-120 w-120"/>
                                <p class="px-70 font-gibson-light text-center text-14 leading-18">
                                    {{ $translate('product.Variant.RemindMe.DescriptionConfirmed') }}
                                </p>
                            </div>
                            <div class="sticky -mx-24 px-24 py-24 bottom-0 bg-sand-10 border-t border-0 border-sand-40">
                                <button class="c-btn c-btn--new-primary w-full justify-center"
                                        @click="modalOpen = false">
                                    {{ $translate('product.Variant.RemindMe.ButtonContinueShopping') }}
                                </button>
                            </div>
                        </div>
                    </transition>
                </div>
            </template>
        </OverlayWrapper>
    </div>
</template>

<script setup lang="ts">
import { type Ref, ref, watch } from 'vue';
import bus from '@/core/bus';
import { OpenVariantRemindMeKey } from '@/project/config/constants';
import OverlayWrapper from '@/project/shared/overlay/OverlayWrapper.vue';
import OverlayHeader from '@/project/shared/overlay/OverlayHeader.vue';
import { checkmark, bell } from '@/project/shared/lottie/lottiejson';
import Api from '@/project/http/api';
import { validationRuleEmail } from '@/project/shared/form/zodValidations';
import CheckoutTerms from '@/project/checkout/CheckoutTerms.vue';
import SessionStorageService, { IStorage } from '@/core/storage.service';
import logging from '@/core/logging.service';
import userStore from '@/store/user.store';
import { type VariantRemindMeInfo } from './variantRemindMeTypes';
import { CloseVariantPickerKey } from '@/project/config/constants';
import Checkbox from '@/project/shared/form/CheckboxCtrl.vue';

const remindMeSessionEmailKey = 'remindMeEmail';

const sku = ref<string | undefined>();
const productId = ref<string | undefined>();
const email = ref<string | undefined>();
const useUsersEmail = ref(false);
const remindMeEmailsAccept = ref(false);

const state: Ref<'entry' | 'saving' | 'saved'> = ref('entry');
const modalOpen = ref(false);

const storageService: IStorage = SessionStorageService.create();

bus.on(OpenVariantRemindMeKey, setData);

watch(() => modalOpen.value, (isOpen) => {
    if (!isOpen) {
        // Sometimes opened from variant picker, so close it when closing remind me
        bus.emit(CloseVariantPickerKey);
    }
});

async function saveRemindMe({isValid}) {
    if (!isValid) {
        return;
    }
    try {
        state.value = 'saving';
        await Api.user.createRemindMe({
            email: email.value!,
            sku: sku.value!,
            productId: productId.value!,
        });

        if (!useUsersEmail.value) {
            storageService.setItem(remindMeSessionEmailKey, email.value);
        }

        state.value = 'saved';
    } catch (error) {
        logging.exception('Failed to save remind me', error);
        state.value = 'entry';
    }
}

function setData(eventData: VariantRemindMeInfo) {
    sku.value = eventData.sku;
    productId.value = eventData.productId;
    
    state.value = 'entry';

    const emailInfo = getEmailInfo();
    email.value = emailInfo.email;
    useUsersEmail.value = emailInfo.isFromLoggedInUser;
    remindMeEmailsAccept.value = false;

    modalOpen.value = true;
}

function getEmailInfo(): { email: string, isFromLoggedInUser: boolean } {
    const emailFromLoggedInUser = userStore.email;

    const email = emailFromLoggedInUser ?? storageService.getItem(remindMeSessionEmailKey) ?? '';
    const isFromLoggedInUser = !!emailFromLoggedInUser;

    return { 
        email, 
        isFromLoggedInUser 
    };
}

</script>