import { ref } from 'vue';
import IPortalOverlay from '@/core/portal-overlay/IPortalOverlay';

const overlayInitiator = ref<HTMLElement | null>();
const activeOverlay = ref<IPortalOverlay[]>([]);

export default function useOverlay() {
    const beginActivation = async(overlay: IPortalOverlay) => {
        // Look for overlay in same portal
        const overlaysToWaitFor = activeOverlay.value.find((o) => o.portal === overlay.portal);
        if (overlaysToWaitFor) {
            await overlaysToWaitFor.close();
        }
        activeOverlay.value.push(overlay);
    };

    const cleanupOverlays = (overlay: IPortalOverlay) => {
        // Remove overlay by id
        const overlayIndex = activeOverlay.value.findIndex((o) => o.id === overlay.id);
        if (activeOverlay.value.length) {
            activeOverlay.value.splice(overlayIndex, 1);
        }
    };
    
    const setOverlayInitiator = () => {
        if(document.activeElement) {
            overlayInitiator.value = document.activeElement as HTMLElement;
        }
    };

    const resetTabOrder = () => {
        if (overlayInitiator.value) {
            overlayInitiator.value.focus();
        }
        overlayInitiator.value = null;
    };

    return {
        overlayInitiator,
        setOverlayInitiator,
        resetTabOrder,
        beginActivation,
        cleanupOverlays
    };

} 