import { blockResolver } from '@/core/spa/componentResolver.service';
import Vue from 'vue';
import BlocksContainer from './BlocksContainer.vue';
import BlockHeader from './BlockHeader.vue';
import BlockTeaserText from './BlockTeaserText.vue';
import BlockHeroBanner from './BlockHeroBanner.vue';
import BlockCampaignProductFocus from './BlockCampaignProductFocus.vue';
import BlockContentSpot from './BlockContentSpot.vue';
import BlockProductList from './BlockProductList.vue';
import BlockImage from './BlockImage.vue';
import BlockText from './BlockText.vue';
import BlockFallback from './BlockFallback.vue';
import BlockTextBoxes from './BlockTextBoxes.vue';
import BlockTextSectionHeader from './BlockTextSectionHeader.vue';
import BlockTextSection from './BlockTextSection.vue';
const BlockInstagram = () => import('./BlockInstagram.vue');
const BlockHeroBannerWithScreens = () => import('./BlockHeroScreens.vue');
const BlockContentSpotTall = () => import('./BlockContentSpotTall.vue');
const BlockProductListContentTeaser = () => import('./BlockProductListContentTeaser.vue');
const BlockArticleAsymmetricalImages = () => import('./article/BlockArticleAsymmetricalImages.vue');
const BlockFullWidthPattern = () => import('./BlockFullWidthPattern.vue');
const BlockProductAssortment = () => import('./BlockProductAssortment.vue');
const BlockLinkGroup = () => import('./BlockLinkGroup.vue');
const BlockContentFocus = () => import('./BlockContentFocus.vue');
const BlockContentFocusPart = () => import('./BlockContentFocusPart.vue');
const BlockProductFocusBlockPart = () => import('./largeProductTeaser/BlockLargeProductTeaserLargeProduct.vue');
const BlockInspirationalSpot = () => import('./BlockInspirationalSpot.vue');
const BlockNewsletter = () => import('./BlockNewsletter.vue');
const BlockLargeProductTeaser = () => import('./BlockLargeProductTeaser.vue');
const BlockContentTeaser = () => import('./BlockContentTeaser.vue');
const BlockContentTeaserImagePart = () => import('./BlockContentTeaserImagePart.vue');
const BlockContentTeaserTextPart = () => import('./BlockContentTeaserTextPart.vue');
const BlockQuote = () => import('./BlockQuote.vue');
const BlockProductCollection = () => import('./BlockProductCollection.vue');
const BlockVideo = () => import('./BlockVideo.vue');
const BlockArticleImage = () => import('./article/BlockArticleImage.vue');
const BlockArticleText = () => import('./article/BlockArticleText.vue');
const BlockHorizontalLine = () => import('./BlockHorizontalLine.vue');
const BlockFaqGroup = () => import('./faq/BlockFaqGroup.vue');
const BlockFindStore = () => import('./BlockFindStore.vue');
const TwoIcons = () => import('./TwoIcons.vue');
const BlockFridayStar = () => import('./BlockFridayStar.vue');
const BlockProductGallery = () => import('./BlockProductGallery.vue');
const BlockProductDisplaySplitView = () => import('./BlockProductDisplaySplitView.vue');
const BlockProductDisplay = () => import('./BlockProductDisplay.vue');
const BlockIconList = () => import('./BlockIconList.vue');
const BlockShoppingList = () => import('./BlockShoppingList.vue');
const BlockSplitTest = () => import('./BlockSplitTest.vue');
const BlockAccordion = () => import('./BlockAccordion.vue');
const BlockUniqueSellingPoints = () => import('./BlockUniqueSellingPoints.vue');

const BlockProductRecommendations = () => import('./BlockProductRecommendations.vue');
const BlockCategoryListCategory = () => import('./category/BlockCategoryListCategory.vue');
const BlockCategoryListTopicTeaser = () => import('./category/BlockCategoryListTopicTeaser.vue');
const BlockCategoryListContentTeaser = () => import('./category/BlockCategoryListContentTeaser.vue');

const BlockTopicListContentTeaser = () => import('./topics/BlockTopicListContentTeaser.vue');

const BlockContentHubGalleryStoryHeading = () => import('./contentHub/BlockContentHubGalleryStoryHeading.vue');
// const BlockFeatureStoryIntroText = () => import('./contentHub/featureStory/BlockFeatureStoryIntroText.vue');
const BlockFeatureStoryFooter = () => import('./contentHub/featureStory/BlockFeatureStoryFooter.vue');
const BlockFeatureStoryCombinedHeader = () => import('./contentHub/featureStory/BlockFeatureStoryCombinedHeader.vue');
const BlockContentHubHeader = () => import('./contentHub/BlockContentHubHeader.vue');
const BlockContentHubSimpleHeader = () => import('./contentHub/BlockContentHubSimpleHeader.vue');
const BlockContentHubQuote = () => import('./contentHub/BlockContentHubQuote.vue');
const BlockContentHubTeaserText = () => import('./contentHub/BlockContentHubTeaserText.vue');
const BlockContentHubText = () => import('./contentHub/BlockContentHubText.vue');
const BlockContentHubDate = () => import('./contentHub/BlockContentHubDate.vue');
const BlockContentHubAnnaAndClaraDialogue = () => import('./contentHub/BlockContentHubAnnaAndClaraDialogue.vue');
const BlockContentHubContentTeaser = () => import('./contentHub/BlockContentHubContentTeaser.vue');
const BlockContentHubContentTeaserNoImage = () => import('./contentHub/BlockContentHubContentTeaserNoImage.vue');
const BlockContentHubQuoteBox = () => import('./contentHub/BlockContentHubQuoteBox.vue');
const BlockContentHubPreviewList = () => import('./contentHub/BlockContentHubPreviewList.vue');
const BlockContentHubImageWithTextBox = () => import('./contentHub/BlockContentHubImageWithTextBox.vue');
const BlockContentHubImage = () => import('./contentHub/BlockContentHubImage.vue');
const BlockContentHubTwoImages = () => import('./contentHub/BlockContentHubTwoImages.vue');
const BlockContentHubExperienceIntro = () => import('./contentHub/experience/BlockContentHubExperienceIntro.vue');
const BlockContentHubExperienceDescription = () => import('./contentHub/experience/BlockContentHubExperienceDescription.vue');
const BlockContentHubExperienceHints = () => import('./contentHub/experience/BlockContentHubExperienceHints.vue');
const BlockContentHubExperienceExit = () => import('./contentHub/experience/BlockContentHubExperienceExit.vue');
const BlockContentHubFrontPageHeader = () => import('./contentHub/inspiration/BlockContentHubFrontPageHeader.vue');
const BlockContentHubMomentPreviewWrapper = () => import('./contentHub/inspiration/BlockContentHubMomentPreviewWrapper.vue');
const BlockContentHubCollectionBlock = () => import('./contentHub/inspiration/BlockContentHubCollectionBlock.vue');
const BlockContentHubThemeBlock = () => import('./contentHub/BlockContentHubThemeBlock.vue');
const BlockContentHubProductsRecommendations = () => import('./contentHub/BlockContentHubProductsRecommendations.vue');
const BlockRoomListRoom = () => import('./rooms/BlockRoomListRoom.vue');

Vue.component('BlocksContainer', BlocksContainer);
blockResolver.register('headerBlock', BlockHeader);
blockResolver.register('linkBlock', BlockLinkGroup);
blockResolver.register('heroBlock', BlockHeroBanner);
blockResolver.register('heroScrollBlock', BlockHeroBannerWithScreens);
blockResolver.register('campaignProductFocusBlock', BlockCampaignProductFocus);
blockResolver.register('productFocusBlockPartBlock', BlockProductFocusBlockPart);
blockResolver.register('contentSpotBlock', BlockContentSpot);
blockResolver.register('contentSpotTallBlock', BlockContentSpotTall);
blockResolver.register('contentTeasersBlock', BlockContentTeaser);
blockResolver.register('contentTeasersBlockImagePart', BlockContentTeaserImagePart);
blockResolver.register('contentTeasersBlockTextPart', BlockContentTeaserTextPart);
blockResolver.register('quoteBlock', BlockQuote);
blockResolver.register('productListBlock', BlockProductList);
blockResolver.register('productListBlockContentTeaserPart', BlockProductListContentTeaser);
blockResolver.register('productCollectionBlock', BlockProductCollection);
blockResolver.register('fridayStarBlock', BlockFridayStar);
blockResolver.register('imageBlock', BlockImage);
blockResolver.register('newsletterSignupBlock', BlockNewsletter);
blockResolver.register('teaserTextBlock', BlockTeaserText);
blockResolver.register('videoBlock', BlockVideo);
blockResolver.register('textBlock', BlockText);
blockResolver.register('productDisplaySplitViewBlock', BlockProductDisplaySplitView);
blockResolver.register('productDisplayBlock', BlockProductDisplay);
blockResolver.register('categoryListCategoryBlock', BlockCategoryListCategory);
blockResolver.register('categoryListTopicTeaserBlock', BlockCategoryListTopicTeaser);
blockResolver.register('categoryListContentTeaserBlock', BlockCategoryListContentTeaser);
blockResolver.register('faqGroupBlock', BlockFaqGroup);
blockResolver.register('articleTextBlock', BlockArticleText);
blockResolver.register('articleImageBlock', BlockArticleImage);
blockResolver.register('articleAsymmetricalImagesBlock', BlockArticleAsymmetricalImages);
blockResolver.register('topicListContentTeaserBlock', BlockTopicListContentTeaser);
blockResolver.register('productAssortmentBlock', BlockProductAssortment);
blockResolver.register('contentFocusBlock', BlockContentFocus);
blockResolver.register('contentFocusBlockPart', BlockContentFocusPart);
blockResolver.register('largeProductTeaserBlock', BlockLargeProductTeaser);
blockResolver.register('findStoreBlock', BlockFindStore);
blockResolver.register('twoIcons', TwoIcons);
blockResolver.register('inspirationalSpotBlock', BlockInspirationalSpot);
blockResolver.register('productGalleryBlock', BlockProductGallery);
blockResolver.register('horizontalLineBlock', BlockHorizontalLine);
blockResolver.register('textSectionHeaderBlock', BlockTextSectionHeader);
blockResolver.register('textSectionBlock', BlockTextSection);
blockResolver.register('textBoxesBlock', BlockTextBoxes);
blockResolver.register('recommendationsBlock', BlockProductRecommendations);
blockResolver.register('iconListBlock', BlockIconList);
blockResolver.register('shoppingListBlock', BlockShoppingList);
blockResolver.register('splitTest', BlockSplitTest);
blockResolver.register('accordionBlock', BlockAccordion);
blockResolver.register('uniqueSellingPointsBlock', BlockUniqueSellingPoints);
blockResolver.register('fullWidthPatternBlock', BlockFullWidthPattern);
blockResolver.register('roomListRoomBlock', BlockRoomListRoom);
blockResolver.register('instagramBlock', BlockInstagram);

blockResolver.register('contentHubGalleryStoryHeaderBlock', BlockContentHubGalleryStoryHeading);
blockResolver.register('contentHubFeatureStoryCombinedHeaderBlock', BlockFeatureStoryCombinedHeader);
// blockResolver.register('contentHubFeatureStoryIntroTextBlock', BlockFeatureStoryIntroText);
blockResolver.register('contentHubFeatureStoryFooterBlock', BlockFeatureStoryFooter);
blockResolver.register('contentHubHeaderBlock', BlockContentHubHeader);
blockResolver.register('contentHubSimpleHeaderBlock', BlockContentHubSimpleHeader);
blockResolver.register('contentHubQuoteBlock', BlockContentHubQuote);
blockResolver.register('contentHubTeaserTextBlock', BlockContentHubTeaserText);
blockResolver.register('contentHubTextBlock', BlockContentHubText);
blockResolver.register('contentHubDateBlock', BlockContentHubDate);
blockResolver.register('contentHubAnnaAndClaraDialogueBlock', BlockContentHubAnnaAndClaraDialogue);
blockResolver.register('contentHubContentTeaserBlock', BlockContentHubContentTeaser);
blockResolver.register('contentHubContentTeaserNoImageBlock', BlockContentHubContentTeaserNoImage);
blockResolver.register('contentHubQuoteBoxBlock', BlockContentHubQuoteBox);
blockResolver.register('contentHubPreviewListBlock', BlockContentHubPreviewList);
blockResolver.register('contentHubImageWithTextBoxBlock', BlockContentHubImageWithTextBox);
blockResolver.register('contentHubImageBlock', BlockContentHubImage);
blockResolver.register('contentHubTwoImagesBlock', BlockContentHubTwoImages);
blockResolver.register('contentHubExperienceIntroBlock', BlockContentHubExperienceIntro);
blockResolver.register('contentHubExperienceDescriptionBlock', BlockContentHubExperienceDescription);
blockResolver.register('contentHubExperienceHintsBlock', BlockContentHubExperienceHints);
blockResolver.register('contentHubExperienceExitBlock', BlockContentHubExperienceExit);
blockResolver.register('contentHubFrontPageHeaderBlock', BlockContentHubFrontPageHeader);
blockResolver.register('contentHubMomentPreviewWrapperBlock', BlockContentHubMomentPreviewWrapper);
blockResolver.register('contentHubCollectionBlock', BlockContentHubCollectionBlock);
blockResolver.register('contentHubThemeBlock', BlockContentHubThemeBlock);
blockResolver.register('contentHubProductRecommendationsBlock', BlockContentHubProductsRecommendations);
// Fallback block component
blockResolver.registerFallback(BlockFallback);
