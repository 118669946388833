<template>
    <div class="c-text-input relative">
        <label v-if="!label && showErrorComputed && error"
               :for="`${id}Day`"
               class="c-text-input__label c-text-input--error opacity-100 mt-15 text-left font-light">
            {{ error }}
        </label>

        <div class="relative flex flex-col"
             :class="{
                 'c-text-input--show-label': hasFocus || hasValue(value) || showErrorComputed && error,
                 'c-text-input--focus': hasFocus,
                 'c-text-input--disabled': isDisabled,
                 'c-text-input--error': showErrorComputed && error
             }">
            <label v-if="label"
                   class="c-text-input__label order-0"
                   :class="{'c-text-input--error': showErrorComputed && error}"
                   :for="`${id}Day`">
                <div
                    :class="{'c-text-input--error': showError && error}">
                    {{ showErrorComputed && error ? error : label }}
                </div>
            </label>
            <div class="c-text-input__input-wrap"
                 :class="{
                     [customClasses]: customClasses,
                     'c-text-input--input-not-empty': hasValue(value),
                     'c-text-input--disabled': isDisabled,
                     'c-text-input--error': showErrorComputed && error,
                     'c-text-input--has-clear': showClear,
                     'c-text-input--placeholder-shown': placeholderShown,
                 }">
                <div class=""
                     :class="{'opacity-100': hasValue(value) || hasFocus , 'opacity-05': !(hasValue(value) || hasFocus)}">
                    <input
                        :id="`${id}Day`"
                        ref="dayRef"
                        v-model="day"
                        v-prohibit-zoom
                        class="c-text-input__input inline-block w-50 pr-5"
                        type="tel"
                        :name="`${name}Day`"
                        :placeholder="$translate('login.CreateUser.Fields.Birthday.Placeholder.Day')"
                        maxlength="2"
                        @input="updateDay"
                        @change="updateDay"
                        @keyup="keyUpDay"
                        @focus="onFocus"
                        @blur="blurDay">
                    <label :for="`${id}Day`"
                           class="sr-only">Day of birth</label>
                    <span class="inline-flex items-center"><span>&ndash;</span></span>
                    <input
                        :id="`${id}Month`"
                        ref="monthRef"
                        v-model="month"
                        v-prohibit-zoom
                        class="c-text-input__input inline-block text-center w-40 px-5"
                        type="tel"
                        :name="`${name}Month`"
                        :placeholder="$translate('login.CreateUser.Fields.Birthday.Placeholder.Month')"
                        maxlength="2"
                        @input="updateMonth"
                        @change="updateMonth"
                        @keyup="keyUpMonth"
                        @focus="onFocus"
                        @blur="blurMonth">
                    <label :for="`${id}Month`"
                           class="sr-only">Month of birth</label>
                    <span class="inline-flex items-center"><span>&ndash;</span></span>
                    <input
                        :id="`${id}Year`"
                        ref="yearRef"
                        v-model="year"
                        v-prohibit-zoom
                        class="c-text-input__input inline-block w-80 pl-10"
                        type="tel"
                        :name="`${name}Year`"
                        :placeholder="$translate('login.CreateUser.Fields.Birthday.Placeholder.Year')"
                        maxlength="4"
                        @input="updateYear"
                        @change="updateYear"
                        @keyup="keyUpYear"
                        @focus="onFocus"
                        @blur="blurYear">
                    <label :for="`${id}Year`"
                           class="sr-only">Year of birth</label>
                </div>
                <div v-prohibit-zoom
                     class="absolute inset-0"
                     :class="{'opacity-0 pointer-events-none': hasValue(value) || hasFocus, 'opacity-100': !(hasValue(value) || hasFocus) }">
                    <label :for="`${id}Day`"
                           class="block c-birthday-input__fake-placeholder fake-placeholder cursor-text">
                        {{ $translate('login.CreateUser.Fields.Birthday.Placeholder') }}
                    </label>
                </div>
                <button ref="tooltipTrigger"
                        v-tracking="{ 'trigger': 'click', 'event' : 'overlay', 'overlayName': tooltipText }"
                        if="tooltipText"
                        type="button"
                        class="absolute flex font-brandon items-center justify-center md:h-20 md:w-20 right-20 text-13 y-center"
                        :class="{ 'mt-8' : label }"
                        @click="shopTooltipChange(true)">
                    ?
                </button>
            </div>
        </div>
        <div ref="tooltipContainer"
             class="relative ml-40"/>
        <div class="text-11 text-center pointer-events-none text-brown-80 opacity-25 pt-5">
            <span class="inline-block h-20 pt-5 align-text-bottom">{{ labelDaysUntilBirthday }}</span>
            <c-icon v-show="daysUntilBirthday === 0"
                    name="gift"
                    height="20"
                    width="15"
                    class="ml-2 align-text-bottom"/>
        </div>
        <div v-if="description"
             class="c-text-input__description mx-20 mt-5 text-15">
            {{ description }}
        </div>
        <v-popover v-if="tooltipText"
                   placement="top-start"
                   :trigger="'manual'"
                   :open="tooltipOpen"
                   :offset="-90"
                   :container="tooltipContainer"
                   @update:open="shopTooltipChange">
            <template #popover>
                <div>
                    <div class=""
                         v-html="tooltipText"/>
                </div>
            </template>
        </v-popover>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, withDefaults, useAttrs, onMounted, nextTick } from 'vue';
import { debounce } from 'lodash-es';
import { millisecondsUntilNextBirthday } from '../string.util';
import translateFilter from '@/core/translation/translate.filter';
import useFieldValidation from '@/core/form/useFieldValidation';
import { validationRuleBirthday, validationRuleMinAge } from './zodValidations';

const props = withDefaults(defineProps<{
  label?: string;
  value: Date | number;
  name: string;
  placeholder?: string;
  description?: string;
  customClasses?: string;
  showClear?: boolean;
  showError?: boolean;
  autofocus?: boolean;
  tooltipText?: string;
}>(), {
    label: '',
    placeholder: '',
    customClasses: '',
    showClear: true,
    showError: true,
    description: '',
    valuePrefix: '',
    tooltipText: '',
});

const day = ref(`${props.value ? new Date(props.value).getDate() : ''}`);
const month = ref(`${props.value ? new Date(props.value).getMonth() + 1 : ''}`);
const year = ref(`${props.value ? new Date(props.value).getFullYear() : ''}`);
const hasFocus = ref(false);
const tooltipOpen = ref(false);
const tooltipTrigger = ref<HTMLButtonElement | null>(null);
const tooltipContainer = ref<HTMLDivElement | null>(null);
const dayRef = ref<HTMLInputElement | null>(null);
const monthRef = ref<HTMLInputElement | null>(null);
const yearRef = ref<HTMLInputElement | null>(null);
const dayBlurred = ref(false);
const monthBlurred = ref(false);
const yearBlurred = ref(false);
const constraints = validationRuleMinAge(validationRuleBirthday(1900, new Date().getFullYear()), 16);

// Access $attrs
const $attrs = useAttrs();
const emit = defineEmits(['input', 'update:error', 'blur', 'days-until-birthday-update']);

const computedValueAsString = computed(() => {
    return year.value && month.value && day.value ? `${year.value.padStart(4, '0')}-${month.value.padStart(2, '0')}-${day.value.padStart(2, '0')}` : '';
});

const { field, validate } = useFieldValidation(props.name, props.label, constraints, computedValueAsString);

const showErrorComputed = computed(() => {
    return props.showError || field.error;
});

const error = computed(() => {
    return field.error;
});

const id = computed(() => {
    return props.name + new Date().valueOf();
});

const invalid = computed(() => {
    return field.invalid;
});

const placeholderShown = computed(() => {
    return !props.value && props.placeholder;
});

const daysUntilBirthday = computed(() => {
    if (invalid.value || props.value === 0 || year.value.length < 4) return -1;
    const nextBirthday = new Date(props.value);
    const differenceInTime = millisecondsUntilNextBirthday(nextBirthday);
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
});

const labelDaysUntilBirthday = computed(() => {
    if (invalid.value || props.value === 0 || daysUntilBirthday.value === -1) return '';
    if (daysUntilBirthday.value === 0) {
        return translateFilter('login.CreateUser.Fields.Birthday.HappyBirthday');
    } else if (daysUntilBirthday.value === 1) {
        return translateFilter('login.CreateUser.Fields.Birthday.OneDayUntillNext');
    }
    return translateFilter('login.CreateUser.Fields.Birthday.DaysUntillNext', daysUntilBirthday.value.toString());
});

const isDisabled = computed(() => {
    return !!$attrs.disabled;
});

// Methods
const updateDay = () => {
    updateValue();
};

const updateMonth = () => {
    updateValue();
};

const updateYear = () => {
    updateValue();
};

const updateValue = () => {
    const timestamp = new Date(parseInt(year.value.padStart(4, '0'), 10), parseInt(month.value, 10) - 1, parseInt(day.value), 1, 1, 1);
    if (Number.isNaN(timestamp.valueOf())) return;
    emit('input', timestamp);
};

const focusDayField = () => {
    dayRef.value && dayRef.value.focus();
};

const keyUpDay = (ev: KeyboardEvent) => {
    if ((parseInt(day.value, 10) > 3 || (day.value.length === 2 && day.value[0] === '0')) &&
      (ev.code.toString().indexOf('Digit') === 0 || ev.code.toString().indexOf('Numpad') === 0) &&
      ev.target === dayRef.value) {
        focusMonthDebounced();
    }
};

const focusMonthField = () => {
    monthRef.value && monthRef.value.select();
};

const keyUpMonth = (ev: KeyboardEvent) => {
    if ((parseInt(month.value, 10) > 2 || (month.value.length === 2 && month.value[0] === '0')) &&
      (ev.code.toString().indexOf('Digit') === 0 || ev.code.toString().indexOf('Numpad') === 0) &&
      ev.target === monthRef.value) {
        focusYearDebounced();
    }
};

const focusYearField = () => {
    yearRef.value && yearRef.value.select();
};

const keyUpYear = () => {
    const now = new Date();
    const yearTwoDigit = now.getFullYear() - 2000;
    const yearInt = parseInt(year.value, 10);
    if (year.value.length === 2 && parseInt(year.value, 10) > yearTwoDigit) {
        year.value = (yearInt + 1900).toString();
    }
};

const blurDay = (ev: FocusEvent) => {
    dayBlurred.value = true;
    prettifyDay();
    onBlur(ev);
};

const prettifyDay = () => {
    if (day.value.toString().length > 0) {
        day.value = day.value.toString().padStart(2, '0');
    }
};

const blurMonth = (ev: FocusEvent) => {
    monthBlurred.value = true;
    prettifyMonth();
    onBlur(ev);
};

const prettifyMonth = () => {
    if (month.value.toString().length > 0) {
        month.value = month.value.toString().padStart(2, '0');
    }
};

const blurYear = (ev: FocusEvent) => {
    yearBlurred.value = true;
    nextTick(() => {
        onBlur(ev);
    });
};

const onBlur = (ev: FocusEvent) => {
    if (dayBlurred.value && monthBlurred.value && yearBlurred.value) {
        validate();
    }
    hasFocus.value = false;
    emit('blur', ev);
};

const onFocus = () => {
    if (isDisabled.value) return;
    hasFocus.value = true;
};

const shopTooltipChange = (state: boolean) => {
    tooltipOpen.value = state;
};

const hasValue = (value: any) => {
    return value !== 0 && value != null;
};

watch(() => props.autofocus, (autofocus) => {
    if (autofocus) {
        try {
            setTimeout(() => {
                focusDayField();
            }, 400);
        } catch (e) {} // Safari sometimes
    }
}, { immediate: true });

watch(() => field.error, (value) => {
    emit('update:error', value);
});

watch(() => daysUntilBirthday.value, (days) => {
    emit('days-until-birthday-update', days < 0 ? false : days);
});

// Debounced functions
const focusMonthDebounced = debounce(focusMonthField, 50);
const focusYearDebounced = debounce(focusYearField, 50);

// Lifecycle hooks
onMounted(() => {
    prettifyDay();
    prettifyMonth();
});

</script>

<style lang="less">
.c-birthday-input__fake-placeholder {
    @apply px-10 py-15 text-14 font-light tracking-medium ;
    margin-top: 1.7rem;
    padding-top: 1.6rem;
    padding-left: 2.1rem;
}
@screen md {
    .c-birthday-input__fake-placeholder {
        @apply text-16;
    }
}
</style>