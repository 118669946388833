<template>
    <article class="flex items-center">
        <span v-if="facetKey"
              class="text-12 font-brandon-regular uppercase pr-8">{{ translateFilter(`search.Filters.${facetKey}`) }}</span>
        <CheckboxSwitch v-model="toggled"
                        class="ml-auto"
                        @input="facetChange"/>
    </article>
</template>

<script setup lang="ts">
import { computed, ref, watch} from 'vue';
import urlHelper from '@/project/facets/urlHelper.service';
import trackingUtils from '@/project/tracking/tracking.utils';
import translateFilter from '@/core/translation/translate.filter';
import { FacetGroup } from '@/types/serverContract';

const props = defineProps<{
    facetGroup: FacetGroup | undefined;
}>();

const toggled = ref(getValueFromFacetGroup(props.facetGroup));
const facetKey = computed(()=> {
    return props.facetGroup?.key;
});

function facetChange() {
    if (facetKey.value) {
        trackingUtils.facetFilter.trackFacetAddOrRemove(toggled.value, facetKey.value, 'true', urlHelper.getFacets());
        urlHelper.updateFacetValue(facetKey.value, 'true', toggled.value);
    } 
}

watch(()=> props.facetGroup, () => {
    toggled.value = getValueFromFacetGroup(props.facetGroup);
});

function getValueFromFacetGroup(facetGroup: FacetGroup| undefined): boolean {
    if (!facetGroup) {
        return false;
    }
    return facetGroup.values.find(v => v.key === 'true')?.selected ?? false;
}
</script>
