<script lang="ts">
import Vue from 'vue';
import DomService from '@/core/dom.service';
import { Component, Watch } from 'vue-property-decorator';
import { ImageType, MediaType, Metadata, SitePageData, TrackingData } from '@/types/serverContract';
import spaStore from '@/core/spa/store/spa.store';
import navStore from '@/store/nav.store';
import httpService, { HttpCancelError, HttpRedirectError } from '@/core/http/http.service';

export const ErrorPageData: SitePageData = {
    navigation: {
        breadcrumb: []
    },
    metadata: {
        id: '',
        index: false,
        languages: [],
        navigationTitle: '',
        seoDescription: '',
        seoImage: {
            name: '',
            url: '',
            extension: '',
            focalPoint: { left: 0, top: 0 },
            height: 0,
            width: 0,
            config: {},
            type: MediaType.Image,
            imageType: ImageType.Unspecified
        },
        seoTitle: '',
        url: ''
    },
    messages: {
        productIds: []
    },
    entity: {
        id: 0,
        provider: ''
    },
    jsonContent: {
        alias: 'error',
        content: {}
    },
    tracking: {} as TrackingData,
    showChatbot: false,
    showWatermelonChatbot: false,
};
@Component
export default class SpaServer extends Vue {
    mounted() {
        // Load first page if not preloaded
        if (!spaStore.pageData) {
            this.fetchPage(this.$route.path);
        }
    }

    @Watch('$route')
    onRouteChange(route, oldRoute) {
        if (route.path === oldRoute.path) {
            // Close menu if navigate to same page as current (e.g. via top menu, only changing pagination / facets).
            navStore.setNavigationStateActive(false);
            return;
        }

        this.fetchPage(route.path);
    }

    @Watch('metadata', {
        immediate: true
    })
    onMetadataChange(metadata: Metadata) {
        if (metadata) {
            DomService.updateMetaData(metadata, spaStore.entity, spaStore.pageData.jsonContent);
        }
    }

    get metadata(): Metadata {
        return spaStore.metadata;
    }

    render() {
        return this.$slots.default![0];
    }

    async fetchPage(path: string): Promise<void> {
        try {
            spaStore.isFetchingSpaPage = true;
            spaStore.mainAriaLabel = null; // Set it to null on page load, when new page is received, that page might set a new aria label.
            const page = await httpService.getPage(path);
            spaStore.updatePage(page);
            spaStore.isFetchingSpaPage = false;
        } catch (error) {
            if (error instanceof HttpCancelError) {
                // Ignore - just because of new page-request while waiting.
            } else if (error instanceof HttpRedirectError) {
                // Ignore - just because of redirect in progress.
            } else {
                spaStore.updatePage(ErrorPageData);
                spaStore.isFetchingSpaPage = false;
            }
        }
    }
}
</script>
