<template>
    <div>
        <label v-if="label"
               class="c-select__label c-select--has-selection">
            {{ label }}
        </label>
        <button type="button"
                class="c-select__select text-left border border-brown-80"
                @click="handleClickSelect">
            {{ labelForCountry(value) }}
        </button>
        <portal-overlay :show.sync="countryOverlayActive"
                        :disable-body-scroll="true"
                        side="right"
                        wrapper-class="w-full md:max-w-480 ml-auto"
                        :show-sticky-header="true"
                        :show-blind="true">
            <div class="bg-white-100 relative z-3 min-h-screen pt-30 md:pt-40">
                <div class="md:max-w-480 mx-auto my-auto px-20 w-full">
                    <div ref="overlayContent"
                         class="pt-20 pb-40">
                        <div v-for="(countryGroup, pix) in getAllCountries"
                             :key="pix"
                             :class="{'border-t': pix !== 0}">
                            <button v-for="(cfix, ix) in countryGroup.options"
                                    :key="cfix.countryCode"
                                    class="flex w-full text-left py-10 relative items-center"
                                    :class="{'border-t border-white-500': ix !== 0, 'font-light': countryValue !== cfix.countryCode}"
                                    type="button"
                                    @click="handleChangeCountry(cfix)">
                                <div>{{ labelForCountry(cfix.countryCode) }}</div>
                                <div class="ml-auto w-20 h-20">
                                    <transition name="fadeover">
                                        <c-icon v-if="countryValue === cfix.countryCode"
                                                :key="'checked' + ix"
                                                name="radiobutton"
                                                color="currentColor"
                                                width="20"
                                                class="c-radiobutton__icon h-20"/>
                                        <c-icon v-else
                                                :key="'unchecked' + ix"
                                                name="radiobutton-unchecked"
                                                color="currentColor"
                                                width="20"
                                                class="c-radiobutton__icon h-20"/>
                                    </transition>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </portal-overlay>
    </div>
</template>
<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import serverContext from '@/core/serverContext.service';
import translateFilter from '@/core/translation/translate.filter';
import { Country } from '@/types/serverContract';

@Component
export default class CountrySelect extends Vue {
    @Prop({
        type: String,
        required: false,
        default: ''
    }) label!: string;

    @Prop({
        type: String,
        required: true
    }) name!: string;

    @Prop({
        type: String,
        required: true
    }) value!: string;

    $refs!: {
        overlayContent: HTMLDivElement
    };

    countryOverlayActive: boolean = false;
    countryValue: string = '';

    @Watch('value', { immediate: true })
    onCountryChange(newCountry) {
        this.countryValue = newCountry;
    }

    get availableCountries(): Country[] {
        return serverContext.availableCountries;
    }

    get getAllCountries(): { label: string, options: Country[] }[] {
        const allCountries = this.availableCountries;
        const marketCountries = allCountries.filter((c) => c.isMarket);
        const nonMarketsCountries = allCountries.filter((c) => !c.isMarket);
        return [{ label: '', options: marketCountries }, { label: '', options: nonMarketsCountries }];
    }

    handleClickSelect() {
        this.countryOverlayActive = !this.countryOverlayActive;
        if (this.countryOverlayActive) {
            try {
                setTimeout(() => this.$refs.overlayContent && (this.$refs.overlayContent as HTMLDivElement).focus(), 400);
            } catch (e) {
            } // Safari sometimes
        }
    }

    handleChangeCountry(prefix: Country) {
        this.countryValue = prefix.countryCode;
        this.$emit('input', prefix.countryCode);
        setTimeout(() => {
            // Let user see changes
            this.countryOverlayActive = false;
        }, 300);
    }

    labelForCountry(countryCode: string): string {
        return `${translateFilter(`country.${countryCode}`)}`;
    }
}
</script>
