<template>
    <form novalidate
          role="form"
          v-bind="$attrs"
          @submit.prevent="handleSubmit">
        <slot :errors="errors"/>
    </form>
</template>

<script setup lang="ts">
import { Ref, provide, ref } from 'vue';
import { FormInjectionContext, formInjectionKey, type FormField } from './formTypes';
import ScrollService from '@/core/scroll/scroll.service';

const props = defineProps<{
        submit?: ({isValid, errors} : { 
            isValid: boolean,
            errors: Record<string, string>  
        }) => void;
    }>();

const emit = defineEmits(['errors']);

const fields = new Map<string, FormField>();
const errors: Ref<Record<string, string>> = ref({});

provide<FormInjectionContext>(formInjectionKey, {
    registerOrUpdateField: (field: FormField) => {
        fields.set(field.name, field);
        updateErrors();
    },
    unregisterField: (field: FormField) => {
        fields.delete(field.name);
        updateErrors();
    },
});

async function handleSubmit() {
    // Validate all fields and wait for async. validation. All fields needs to have an error value of null (no error) to be valid.
    const validationErrors = {};
    const validationPromises = Array.from(fields.values()).map(validateField);
    fields.forEach(async(field, fieldName) => {
        const validationPromise = validateField(field);
        validationPromises.push(validationPromise);
        const error = await validationPromise;
        if (error) {
            validationErrors[fieldName] = error;
        }
        
    });
    await Promise.all(validationPromises);
    const isValid = Object.keys(validationErrors).length === 0;

    if (!isValid) {
        scrolltoError();
    }

    props.submit && props.submit({
        isValid,
        errors: validationErrors,
    });    
}

const validateField = async(field: FormField): Promise<string | null> => {
    return await field.validate();
};

const updateErrors = () => {
    errors.value = getErrors();
    emit('errors', errors.value);

    function getErrors() {
        const validationErrors: Record<string, string> = {};
        fields.forEach((field, fieldName) => {
            const error = field.error;
            if (error) {
                validationErrors[fieldName] = error;
            }
        });
        return validationErrors;
    }
};

const scrolltoError = () => {
    const element = document.querySelector('.has-validation-error');
    element && ScrollService.scrollInToView(element);
};

</script>
