import { SpaPageRenderedEventKey } from '@/core/spa/router';
import bus from '@/core/bus';
import spaStore from '@/core/spa/store/spa.store';
import basketStore from '@/store/basket.store';
import { watch } from 'vue';

declare global {
    interface Window {
        watermelonWidget: any;
    }
}

const embedSrc = 'https://chatwidget-prod.web.app/embed/init.iife.js';
let widgetElementChat: Element | null = null;
let widgetElementButton: Element | null = null;
let scriptElement: HTMLScriptElement | null = null;

bus.on(SpaPageRenderedEventKey, () => {
    handleChatbotInit();
});

function getWatermelonWidgetElement() {
    return document.querySelector('#watermelon-widget-wrapper');
}

function getWatermelonWidgetButton() {
    return document.querySelector('watermelon-widget-button');
}

function setDisplayValueOfElements(displayValue: string = 'none') {
    if (widgetElementChat && widgetElementButton) {
        [widgetElementChat, widgetElementButton].forEach(elm => {
            elm.setAttribute('style', `display: ${displayValue}`);
        });
    }
}

async function handleChatbotInit() {
    if (spaStore.chatbotWatermeolActive ) {
        await loadWatermelonWidget();
    }
    else if (scriptElement) {
        if (!widgetElementChat || !widgetElementButton) {
            await tryToGetWatermelonWidgetElements();
            setDisplayValueOfElements('none');
        } 
        else {
            setDisplayValueOfElements('none');
        }
    }
}

async function loadWatermelonWidget(): Promise<void> {
    const existingScript = document.querySelector(`script[src="${embedSrc}"]`);
    if (existingScript) {
        setDisplayValueOfElements('block');
        return;
    }

    return new Promise((resolve, reject) => {
        scriptElement = document.createElement('script');
        scriptElement.type = 'module';
        scriptElement.src = embedSrc;
        scriptElement.setAttribute('data-watermelon-widget-id', '0enWBLiB0bJ');
        scriptElement.setAttribute('data-watermelon-settings-id', '11985');
        scriptElement.defer = true;
        scriptElement.onload = () => resolve();
        scriptElement.onerror = () => reject(new Error('Failed to load the Watermelon widget script'));
        document.body.append(scriptElement);
    });
}

async function tryToGetWatermelonWidgetElements() {
    const retryInterval = 100;
    const maxTries = 3 * 1000 / retryInterval; 
    let timesTried = 0;

    do {
        widgetElementChat = getWatermelonWidgetElement();
        widgetElementButton = getWatermelonWidgetButton();

        if (!widgetElementChat || !widgetElementButton) {
            console.log('Watermelon widget elements not found. Retrying...');
            await sleep(retryInterval);
        }
    } while (++timesTried < maxTries);

    async function sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}

watch(() => basketStore.basketActive, async(newValue) => {
    if (newValue && spaStore.chatbotWatermeolActive) {
        await tryToGetWatermelonWidgetElements();
        setDisplayValueOfElements('none');
    }
    else if (spaStore.chatbotWatermeolActive) {
        setDisplayValueOfElements('block');
    }
});