<template>
    <div class="c-text-input relative"
         :class="{'has-validation-error': error}">
        <div class="flex flex-col"
             :class="{
                 'c-text-input--show-label': phoneHasFocus || hasValue(phoneValue) || showError && error,
                 'c-text-input--focus': phoneHasFocus,
                 'c-text-input--disabled': isDisabled,
                 'c-text-input--error': showError && error
             }">
            <label v-if="label"
                   class="c-text-input__label order-0"
                   :for="id">
                <div
                    :class="{'c-text-input--error': showError && error}">
                    {{ showError && error ? error : label }}
                </div>
            </label>
            <div class="c-text-input__input-wrap flex"
                 :class="{
                     'c-text-input--input-not-empty': hasValue(phoneValue),
                     'c-text-input--disabled': isDisabled,
                     'c-text-input--error': showError && error,
                     'c-text-input--placeholder-shown': placeholderShown,
                 }">
                <div>
                    <button type="button"
                            class="c-text-input__value-prefix font-light flex items-center pt-13 pb-15"
                            @click="handleClickSelect">
                        {{ shortLabelForPrefix(getSelectedCountry) }}
                        <span class="text-center w-30">
                            <c-icon name="chevron"
                                    width="11"
                                    class="u-reverse-rotate-90"/>
                        </span>
                    </button>
                </div>
                <div class="flex-grow">
                    <input
                        :id="id"
                        ref="input"
                        v-prohibit-zoom
                        :pattern="phonePattern"
                        class="c-text-input__input order-1"
                        :name="name"
                        :value="phoneValue"
                        :placeholder="placeholder"
                        v-bind="$attrs"
                        :required="required"
                        @input="onPhoneInput"
                        @blur="onPhoneBlur"
                        @focus="onPhoneFocus"
                        v-on="listeners">
                </div>
                <button ref="tooltipTrigger"
                        v-tracking="{ 'trigger': 'click', 'event' : 'overlay', 'overlayName': tooltipText }"
                        if="tooltipText"
                        type="button"
                        class="absolute flex font-brandon items-center justify-center md:h-20 md:w-20 right-20 text-13 y-center"
                        @click="shopTooltipChange(true)">
                    ?
                </button>
            </div>
        </div>
        <div ref="tooltipContainer"
             class="relative ml-40"/>
        <v-popover v-if="tooltipText"
                   placement="top-start"
                   :trigger="'manual'"
                   :open="tooltipOpen"
                   :offset="-70"
                   :container="$refs.tooltipContainer"
                   @update:open="shopTooltipChange">
            <template #popover>
                <div v-html="tooltipText"/>
            </template>
        </v-popover>
        <portal-overlay :show.sync="phonePrefixOverlayActive"
                        :disable-body-scroll="true"
                        side="right"
                        wrapper-class="w-full md:max-w-480 ml-auto"
                        :show-sticky-header="true"
                        :show-blind="true"
                        portal="countrySelect">
            <div class="bg-white-100 relative z-3 min-h-screen pt-30 md:pt-40">
                <div class="md:max-w-480 mx-auto my-auto px-20 w-full">
                    <div ref="overlayContent"
                         class="pt-20 pb-40">
                        <div v-for="(prefixGroup, pix) in getAllPhonePrefixes"
                             :key="pix"
                             :class="{'border-t': pix !== 0}">
                            <button v-for="(pfix, ix) in prefixGroup.options"
                                    :key="pfix.countryCode"
                                    class="flex w-full text-left py-10 relative items-center"
                                    :class="{'border-t border-white-500': ix !== 0, 'font-light': phonePrefixValue !== pfix.phonePrefix}"
                                    type="button"
                                    @click="handleChangePrefix(pfix)">
                                <div>{{ labelForPrefix(pfix) }}</div>
                                <div class="ml-auto w-20 h-20">
                                    <transition name="fadeover">
                                        <c-icon v-if="phonePrefixValue === pfix.phonePrefix"
                                                :key="'checked' + ix"
                                                name="radiobutton"
                                                color="currentColor"
                                                width="20"
                                                class="c-radiobutton__icon h-20"/>
                                        <c-icon v-else
                                                :key="'unchecked' + ix"
                                                name="radiobutton-unchecked"
                                                color="currentColor"
                                                width="20"
                                                class="c-radiobutton__icon h-20"/>
                                    </transition>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </portal-overlay>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, withDefaults, useAttrs, toRef, Ref, useListeners } from 'vue';
import { z } from 'zod';
import useFieldValidation from '@/core/form/useFieldValidation';
import { Country, Phone } from '@/types/serverContract';
import serverContext from '@/core/serverContext.service';
import translateFilter from '@/core/translation/translate.filter';

// Props
const props = withDefaults(defineProps<{
  label?: string,
  name: string,
  value: Phone,
  placeholder?: string,
  tooltipText?: string,
  showError?: boolean,
  constraints: z.Schema<any>,
}>(), {
    label: '',
    placeholder: '',
    tooltipText: ''
});

const tooltipOpen = ref(false);

const overlayContent: Ref<HTMLDivElement | null> = ref(null);
const tooltipTrigger: Ref<HTMLButtonElement | null> = ref(null);
const tooltipContainer: Ref<HTMLDivElement | null> = ref(null);

const phonePrefixOverlayActive = ref(false);
const phoneValue = ref('');
const phonePrefixValue = ref('');

const phoneHasFocus = ref(false);

const $attrs = useAttrs();
const $listeners = useListeners();
const emit = defineEmits(['input', 'update:error', 'blur']);

const { field, validate } = useFieldValidation(props.name, props.label, toRef(props, 'constraints'), phoneValue);

const id = props.name + new Date().valueOf();

const availableCountries = serverContext.availableCountries;

const getAllPhonePrefixes = computed(() : { label: string, options: Country[] }[] =>  {
    const allCountries = availableCountries;
    const marketCountries = allCountries.filter((c) => c.isMarket);
    const nonMarketsCountries = allCountries.filter((c) => !c.isMarket);
    return [{ label: '', options: marketCountries }, { label: '', options: nonMarketsCountries }];
}
);

function handleClickSelect() {
    phonePrefixOverlayActive.value = !phonePrefixOverlayActive.value;
    try {
        setTimeout(() => overlayContent.value && (overlayContent.value as HTMLDivElement).focus(), 400);
    } catch (e) {} // Safari sometimes
}

function handleChangePrefix(prefix: Country) {
    phonePrefixValue.value = prefix.phonePrefix;
    onPhoneUpdate();
    setTimeout(() => {
        // Let user see changes
        phonePrefixOverlayActive.value = false;
    }, 300);
}

function labelForPrefix(prefix: Country): string {
    return `${translateFilter(`country.${prefix.countryCode}`)} (${prefix.phonePrefix})`;
}

function shortLabelForPrefix(prefix: Country): string {
    if (!prefix) return '';
    return `${prefix.countryCode} (${prefix.phonePrefix})`;
}

function onPhoneInput(ev) {
    const updatedPhone = ev.target?.value ?? '';
    phoneValue.value = updatedPhone;

    onPhoneUpdate();
}

function onPhoneBlur(ev) {
    if (field.dirty) {
        validate();
    }
    phoneHasFocus.value = false;
    emit('blur', ev);
}

function onPhoneFocus() {
    if (isDisabled.value) return;
    phoneHasFocus.value = true;
}

function onPhoneUpdate() {
    const val: Phone = { phonePrefix: phonePrefixValue.value, number: phoneValue.value };
    emit('input', val);
}

const getSelectedCountry = computed((): Country => {
    return serverContext.getSelectedCountryPhone(props.value.phonePrefix);
}
);

function getPhonePrefix(): string {
    return getSelectedCountry.value.phonePrefix;
}

function getPhoneNumber(): string {
    if (!props.value) {
        return '';
    } else {
        return props.value.number || '';
    }
}

const listeners = computed(() => {
    // eslint-disable-next-line
    const { input, blur, ...restListeners } = $listeners;
    return restListeners;
});

function hasValue(value: any) {
    return value !== '' && value != null;
}

const isDisabled = computed((): boolean => {
    return !!$attrs.disabled;
});

const error = computed((): string | null => {
    return field.error;
});

const required = computed(() => {
    return field.required; 
});

const placeholderShown = computed(() => {
    return !phoneValue.value && props.placeholder;
});

function shopTooltipChange(state) {
    tooltipOpen.value = state;
}

const phonePattern = computed(() => {
    return getSelectedCountry.value.phoneNumberPattern ?? '[0-9]*';
});

watch(() => error.value, (value: string | null) => {
    emit('update:error', value);
});

watch(() => props.value, () => {
    phonePrefixValue.value = getPhonePrefix();
    phoneValue.value = getPhoneNumber();
}, { immediate: true });


</script>