import { type ZodTypeAny } from 'zod';
import { ref, watch, unref, type Ref, reactive, onUnmounted, onMounted, inject, ComputedRef } from 'vue';
import { FormField, FormInjectionContext, formInjectionKey } from './formTypes';

// Build in in Vue 3.3
type MaybeRef<T> = T | Ref<T>;
type CustomValidatorType = (value: any) => string | null;

interface OptionsType { 
    mode?: 'eager' | 'lazy'
}
export default function <T extends ZodTypeAny>(name: string, 
                                               translatedFieldName: string, 
                                               validation: MaybeRef<T> | CustomValidatorType, 
                                               data: MaybeRef<string | number | boolean | null | undefined> | ComputedRef<string | number | boolean | null | undefined>, 
                                               options: OptionsType = { 
                                                   mode: 'lazy',
                                               }
) {

    const field = reactive<FormField>({
        name: name,
        dirty: false,
        validated: false,
        required: false,
        valid: false,
        invalid: true,
        error: null,
        validate
    }) as FormField;

    const validationForm = inject<FormInjectionContext | null>(formInjectionKey, null);
    validationForm?.registerOrUpdateField(field);

    const validationStarted = ref(false);
    let unwatch: null | (() => void) = null;

    onMounted(() => {        
        unwatch = watch(
            () => unref(data),
            async() => {
                field.dirty = true;
                validationStarted.value && validate();
            },
            { deep: true }
        );

        if (options.mode === 'eager') {
            validate();
        }

        // Set required state from whether an empty string is valid. Only possible with zod validation
        if (!isCustomValidation(validation)) {
            field.required = !!validation && !!unref(validation).safeParse('').error;
        }
    });

    async function validate() {
        validationStarted.value = true;

        if (isCustomValidation(validation)) {
            field.error = validation(unref(data));
        } else {
            // Use Zod
            const result = await unref(validation!).safeParseAsync(unref(data));
            field.error = result.success ? null : setFieldNameInError(result.error.issues[0].message);            
        }
        field.validated = true;
        field.valid = field.error === null;
        field.invalid = !field.valid;

        return field.error;
    }

    const setFieldNameInError = (message: string) => {
        // This "secret" key (__name__) is set in the zod validation functions for later substitution when translated fieldname is known (now)
        return message.replace('__field__', translatedFieldName);
    };

    const isCustomValidation = (value: typeof validation): value is CustomValidatorType => {
        return value instanceof Function;
    };

    watch(field, () => {
        validationForm?.registerOrUpdateField(field);
    });

    onUnmounted(() => {
        unwatch && unwatch();
        validationForm?.unregisterField(field);
    });

    return { 
        field,
        validate
    };
}
